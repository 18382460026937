@font-face {
  font-family: 'mdeditor';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/mdeditor.eot?#iefix) format('embedded-opentype'), url(../fonts/mdeditor.woff?70673711) format('woff'), url(../fonts/mdeditor.ttf?70673711) format('truetype'), url(../fonts/mdeditor.svg?70673711#mdeditor) format('svg');
}

[class*=' md-icon-']::before,
[class^='md-icon-']::before {
  display: inline-block;
  font-family: mdeditor;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1em;
  margin-left: .2em;
  margin-right: .2em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.md-icon-mdeditor::before {
  content: '\e804';
}

.md-icon-mdeditor {
  color: darken($brand-info, 10);
}

.navbar-inverse .md-icon-mdeditor:hover {
  color: $body-bg;
}
