// Bootstrap v3.3.6 (http://getbootstrap.com)
// Copyright 2011-2015 Twitter, Inc.
//  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
// Core variables and mixins

//== Colors
//

$brand-primary: #325d88 !default;
$brand-success: #93c54b !default;
$brand-info: #29abe0 !default;
$brand-warning: #f47c3c !default;
$brand-danger: #d9534f !default;

@import 'bootstrap/variables';

$navbar-inverse-bg: lighten($gray-darker, 4);
$breadcrumb-color: $gray;
//see https://v4-alpha.getbootstrap.com/content/reboot/#native-font-stack
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

@import 'bootstrap/mixins';
// Reset and dependencies
@import 'bootstrap/normalize';
@import 'bootstrap/print';
//@import 'bootstrap/glyphicons';
// Core CSS
@import 'bootstrap/scaffolding';
@import 'bootstrap/type';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
// Components
@import 'bootstrap/component-animations';
@import 'bootstrap/dropdowns';
@import 'bootstrap/button-groups';
@import 'bootstrap/input-groups';
@import 'bootstrap/navs';
@import 'bootstrap/navbar';
@import 'bootstrap/breadcrumbs';
@import 'bootstrap/pagination';
@import 'bootstrap/pager';
@import 'bootstrap/labels';
@import 'bootstrap/badges';
@import 'bootstrap/jumbotron';
@import 'bootstrap/thumbnails';
@import 'bootstrap/alerts';
@import 'bootstrap/progress-bars';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/panels';
@import 'bootstrap/responsive-embed';
@import 'bootstrap/wells';
@import 'bootstrap/close';
// Components w/ JavaScript
@import 'bootstrap/modals';
//@import 'bootstrap/tooltip';
//@import 'bootstrap/popovers';
@import 'bootstrap/carousel';
// Utility classes
@import 'bootstrap/utilities';
@import 'bootstrap/responsive-utilities';
