.md-extent-container {
  border: 1px solid $gray-lighter;
  margin-left: 0;
  margin-right: 0;
  min-height: 250px;
  padding: 15px 0;

  .md-extent-description,
  .leaflet-container,
  .alert {
    height: 250px;
  }
}
