.sidebar-nav {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: #{$sidebar-width}px;
  height: 100vh;
  margin: 0;
  padding: 53px 0 26px;
  list-style: outside none none;
  background-color: $navbar-inverse-bg;

  li {
    line-height: 40px;
    text-indent: 0;

    .fa {
      text-indent: 0;
    }

    [class^='icon-'] {
      text-indent: 0;
    }

    a {
      overflow: hidden;
      white-space: nowrap;
      text-decoration: none;
      text-overflow: ellipsis;
      color: lighten($gray-dark, 40);

      &:active {
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }
    }

    > a:hover {
      text-decoration: none;
      color: $body-bg;
      background: rgba($gray-base, .2) none repeat scroll 0 0;
    }
  }

  > .sidebar-brand {
    background-color: $navbar-inverse-bg;
    border-top: 1px solid $body-bg;
    display: none;
    font-size: 18px;
    height: 52.5px;
    line-height: 52.5px;
    padding: 0 10px;
    position: fixed;
    top: 0;
    width: #{$sidebar-width}px;
    z-index: 1000;

    a {
      color: $navbar-inverse-link-color;;

      &:hover {
        background: none repeat scroll 0 0;
        color: $body-bg;
      }
    }

    .md-app-version {
      color: $brand-info;
      font-size: 11px;
      font-variant: small-caps;
      padding-left: 3px;
      position: relative;
      top: -7px;

      &.alpha {
        color: $brand-danger;
      }

      &.beta {
        color: $brand-warning;
      }
    }
  }
}

#md-sidebar-lists {
  height: 100%;

  .panel-group {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 0 0 0px;
    background-color: $gray-darker;

    .panel {
      border-image: none;
      border-radius: 0;
      border-style: solid none none;
      border-width: 1px 0 0;
      margin-top: 0;

      .btn {
        font-size: 16px;
        width: 38px;
        height: 40px;
        margin: 0;
        padding: 9px;
        text-indent: 0;
        color: $body-bg;
        border: 0 none;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0;

        &.btn-primary {
          background-color: darken($brand-primary, 6%);

          &:hover {
            background-color: darken($brand-primary, 12%);
          }
        }
      }

      .btn-group {
        margin-right: -1px;
        padding: 0;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0;
        background-color: $body-bg;
      }
    }

    .panel-heading {
      padding: 0;

      .panel-title a {
        line-height: 40px;
        padding-left: 10px;
        color: $body-bg;

        &:hover {
          //background-color: darken($brand-primary, 12%);
          color: lighten($brand-primary, 46.5%);
        }

        span::before {
          content: '\f106';
        }

        &.collapsed span::before {
          content: '\f107';
        }
      }
    }
  }

  .list-group {
    border-top: 0 none;

    .list-group-item {
      line-height: 40px;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 0;

      &:hover {
        background-color: lighten($brand-primary, 46.5);

        .sidebar-row {
          color: $brand-primary;
        }
      }
    }
  }

  a.sidebar-row {
    max-width: #{$sidebar-width - 55}px;
    padding-left: 10px;
    display: block;

    &.active {
      &.text-warning {
        color: darken($brand-warning, 15);
      }

      &.text-danger {
        color: darken($brand-danger, 15);
      }

      &.text-success {
        color: darken($brand-success, 15);
      }
    }
  }
}
