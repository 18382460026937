.md-translator-preview {
  animation: fadeIn 1s ease;
  min-height: 300px;

  .card-block,
  .form-group,
  .md-translator-area,
  textarea {
    display: block;
    height: 100% !important;
    width: 100% !important;
  }

  .md-translator-area textarea {
    height: 95% !important;
  }

  iframe {
    min-height: 500px;
  }

  &.full-screen {
    .card-footer {
      display: none;
    }

    iframe {
      height: 95%;
    }
  }
}

.md-translator-error {
  background-color: $body-bg;

  &.danger .card-header {
    background-color: $brand-danger;
  }

  &.success .card-header {
    background-color: $brand-success;
  }

  &.info .card-header {
    background-color: $brand-info;
  }

  &.warning .card-header {
    background-color: $brand-warning;
  }

  .list-group-item-heading {
    .label {
      display: inline-block;
    }
  }

  &.card-inverse .card-title a {
    color: $body-bg;

    &:hover {
      color: darken($body-bg, 10);
    }
  }

}
