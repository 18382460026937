.md-message-container {
  bottom: 0;
  height: 0;
  position: fixed;
  right: 0;
  z-index: 10000;
}

.md-message-wrapper {
  bottom: 10px;
  overflow: hidden;
  position: absolute;
  right: 10px;

  .alert {
    animation: slide-in .5s forwards;
    position: relative;
    transform: translateX(100%);
    width: 300px;

    &.exiting {
      animation: slide-out .5s forwards;
    }
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(200%);
  }
}
