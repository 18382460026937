//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: 532px !default;
$sidebar-width: 300;

@import 'bootstrap_custom';

$md-nav-background: lighten($gray-darker, 4);
$md-text-info-dark: darken($brand-info, 5);
$white: #fff;

@import 'background';
@import 'col_xxl';
@import 'col_xxxl';
@import 'power_select_custom';
@import 'ember-power-select/themes/bootstrap';
@import 'card';
@import 'ember-power-select';
@import 'jquery.jsonview';
@import 'mdeditor_font';
@import 'typography';
@import 'validation';
@import 'tooltip';
@import 'layout';
@import 'table';
@import 'sidebar_nav';
@import 'scrollspy';
@import 'media';
@import 'navbars';
@import 'breadcrumb';
@import 'help';
@import 'form_elements';
@import 'fade';
@import 'drop_shadow';
@import 'messages';
@import 'modal';
@import 'loader';
@import 'filepicker';
@import 'import_export';
@import 'jsonview';
@import 'toggle';
@import 'button';
@import 'extent';
@import 'keywords';
@import 'card_custom';
@import 'dashboard';
@import 'translator';
@import 'spinner';
@import 'control_sidebar';
@import 'classification';
@import 'collapse';
@import 'alert';
@import 'borders';

//modals
@import 'ember-modal-dialog/ember-modal-structure';
@import 'ember-modal-dialog/ember-modal-appearance';
//simple-mde
@import 'simple-mde';

//full-screen fix for liquid-fire
.liquid-child.full-screen,
.liquid-container.full-screen {
  transform: none !important;
}
