.ember-tooltip, .ember-popover {
  z-index: 1102;
}

.badge.tooltip {
  &:hover {
    opacity: .9;
  }
}

.md-tooltip {
  text-shadow: none;

  &.ember-popover {
    // background-color: lighten($brand-warning, 35);
    border-color: $gray-dark;
    color: $gray-dark;
    overflow: visible;
    white-space: normal;

    &[x-placement^="top"] .ember-popover-arrow {
      border-top-color: $gray-dark;
    }

    &[x-placement^="left"] .ember-popover-arrow {
      border-left-color: $gray-dark;
    }

    &[x-placement^="right"] .ember-popover-arrow {
      border-right-color: $gray-dark;
    }

    &[x-placement^="bottom"] .ember-popover-arrow {
      border-bottom-color: $gray-dark;
    }

    .title {
      border-bottom: 1px solid lighten($gray-dark, 50);
    }
  }

  &.danger {
    background-color: darken($brand-danger, 15);

    &.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
      border-top-color: darken($brand-danger, 15);
    }

    &.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
      border-left-color: darken($brand-danger, 15);
    }

    &.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
      border-right-color: darken($brand-danger, 15);
    }

    &.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
      border-bottom-color: darken($brand-danger, 15);
    }

    &.ember-popover {
      background-color: lighten($brand-danger, 40);
      border-color: $brand-danger;

      &[x-placement^="top"] .ember-popover-arrow {
        border-top-color: $brand-danger;
      }

      &[x-placement^="left"] .ember-popover-arrow {
        border-left-color: $brand-danger;
      }

      &[x-placement^="right"] .ember-popover-arrow {
        border-right-color: $brand-danger;
      }

      &[x-placement^="bottom"] .ember-popover-arrow {
        border-bottom-color: $brand-danger;
      }

      .title {
        border-bottom: 1px solid lighten($brand-danger, 20);
        color: $brand-danger;
      }
    }
  }

  &.warning {
    background-color: darken($brand-warning, 1);

    &.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
      border-top-color: darken($brand-warning, 5);
    }

    &.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
      border-left-color: darken($brand-warning, 5);
    }

    &.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
      border-right-color: darken($brand-warning, 5);
    }

    &.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
      border-bottom-color: darken($brand-warning, 5);
    }

    &.ember-popover {
      background-color: lighten($brand-warning, 40);
      border-color: $brand-warning;

      &[x-placement^="top"] .ember-popover-arrow {
        border-top-color: $brand-warning;
      }

      &[x-placement^="left"] .ember-popover-arrow {
        border-left-color: $brand-warning;
      }

      &[x-placement^="right"] .ember-popover-arrow {
        border-right-color: $brand-warning;
      }

      &[x-placement^="bottom"] .ember-popover-arrow {
        border-bottom-color: $brand-warning;
      }

      .title {
        border-bottom: 1px solid lighten($brand-warning, 20);
        color: $brand-warning;
      }
    }
  }

  &.info {
    background-color: darken($brand-info, 10);

    &.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
      border-top-color: darken($brand-info, 15);
    }

    &.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
      border-left-color: darken($brand-info, 15);
    }

    &.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
      border-right-color: darken($brand-info, 15);
    }

    &.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
      border-bottom-color: darken($brand-info, 15);
    }

    &.ember-popover {
      background-color: lighten($brand-info, 45);
      border-color: $brand-info;

      &[x-placement^="top"] .ember-popover-arrow {
        border-top-color: $brand-info;
      }

      &[x-placement^="left"] .ember-popover-arrow {
        border-left-color: $brand-info;
      }

      &[x-placement^="right"] .ember-popover-arrow {
        border-right-color: $brand-info;
      }

      &[x-placement^="bottom"] .ember-popover-arrow {
        border-bottom-color: $brand-info;
      }

      .title {
        border-bottom: 1px solid lighten($brand-info, 20);
        color: $brand-info;
      }
    }
  }

  &.primary {
    background-color: $brand-primary;

    &.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
      border-top-color: $brand-primary;
    }

    &.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
      border-left-color: $brand-primary;
    }

    &.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
      border-right-color: $brand-primary;
    }

    &.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
      border-bottom-color: $brand-primary;
    }

    &.ember-popover {
      background-color: $white;
      border-color: $brand-primary;

      &[x-placement^="top"] .ember-popover-arrow {
        border-top-color: $brand-primary;
      }

      &[x-placement^="left"] .ember-popover-arrow {
        border-left-color: $brand-primary;
      }

      &[x-placement^="right"] .ember-popover-arrow {
        border-right-color: $brand-primary;
      }

      &[x-placement^="bottom"] .ember-popover-arrow {
        border-bottom-color: $brand-primary;
      }

      .title {
        border-bottom: 1px solid lighten($brand-primary, 20);
        color: $brand-primary;
      }
    }
  }
}

.ember-popover {
  @include text-overflow;
  max-width: 300px;

  hr {
    margin-bottom: 2px;
    margin-top: 2px;
  }
}
