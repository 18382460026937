@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
  }

  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }

  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}

.md-load-indicator {
  text-align: center;

  .md-load-wrapper {
    background: none;
    height: 300px;
    margin: auto;
    position: relative;
    width: 300px;

    div {
      animation: uil-ripple 3s ease-out infinite;
      border-radius: 50%;
      border-style: solid;
      border-width: 12px;
      height: 0;
      left: 50%;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 50%;
      width: 0;
    }

    div:nth-of-type(1) {
      border-color: $brand-warning;
    }

    div:nth-of-type(2) {
      border-color: $brand-primary;
      animation-delay: 1.5s;
    }
  }
}
